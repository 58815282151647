import Axios from "axios";
import { AuthService } from "./auth-service";
import { InvoiceService } from "./invoice-service";
import { InventoryService } from "./inventory-service";
import { VehicleAcquisitionService } from "./vehicle-acquisition-service";
import store from "@/store/store";
import { SettingService } from "./settings-service";
import { SaleReportService } from "@/services/sale-report";

export class ApiService {
  axios = Axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  constructor() {
    this.setupAuthInjector();
  }

  setupAuthInjector() {
    this.axios.interceptors.request.use(async (config) => {
      config.headers.Authorization = store.getters.userInfo.token;
      return config;
    });
    this.axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response?.status === 401) {
          store.dispatch("user/logoutHandler");
        }
        return Promise.reject(error);
      }
    );
  }

  auth = new AuthService(this.axios);
  invoice = new InvoiceService(this.axios);
  vehicleAcquisition = new VehicleAcquisitionService(this.axios);
  inventory = new InventoryService(this.axios);
  invoice = new InvoiceService(this.axios);
  setting = new SettingService(this.axios);
  saleReport = new SaleReportService(this.axios);
}

export const apiService = new ApiService();
