import firebaseApp from "../firebaseConfig";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { saveFCMToken } from "../../../api/user";
import store from "../../../store/store";

const messaging = getMessaging(firebaseApp);

async function getFCMToken() {
  if (!messaging) return null; // Return null if messaging is not initialized
  try {
    const token = await getToken(messaging, {
      vapidKey: process.env.VUE_APP_FIREBASE_CLOUD_MESSAGING_PUBLIC_KEY,
    });
    console.log(token);

    return token; // Return the retrieved token
  } catch (error) {
    console.error("Error retrieving FCM token:", error);
    return null; // Return null in case of error
  }
}

// Function to receive messages
function receiveMessage(callback) {
  if (!messaging) return; // Check if messaging is initialized
  onMessage(messaging, (payload) => {
    console.log("Message received: ", payload); // Handle the received message
    if (callback) {
      callback(payload); // Call the callback function to handle message
    }
  });
}

// Function to initialize FCM and get the user token
async function initFCM(user) {
  console.log("getToken is running");
  const fcmToken = await getFCMToken();
  if (!fcmToken) return; // Return null if no token is received
  store.dispatch("user/setFCMToken", fcmToken);
  try {
    await saveFCMToken({
      userID: user.userId,
      fcmToken: fcmToken,
    });
  } catch (error) {
    throw new Error(error);
  }

  return fcmToken; // Return the FCM token
}

function removeFCMToken() {
  store.dispatch("user/removeFCMToken");
}

async function checkNotificationPermission(permission, user) {
  if (permission === "granted" && user.token) {
    await initFCM(user);
  } else if (permission !== "granted") {
    removeFCMToken();
  }
}

export {
  messaging,
  receiveMessage,
  getToken,
  initFCM,
  checkNotificationPermission,
};
