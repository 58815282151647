import request from "@/utils/request";

export function login(data) {
  return request({
    url: "/login",
    method: "post",
    data,
  });
}

export function getForgotPasswordOTP(data) {
  return request({
    url: "/user/forgotPassword",
    method: "post",
    params: data,
  });
}

export function resetPassword(data) {
  return request({
    url: "/user/resetPassword",
    method: "post",
    params: data,
  });
}
