<template>
  <div class="carousel-wrapper">
    <div class="carousel" :style="{ width, height }" v-on:scroll.passive="handleScroll">
      <slot />
    </div>
    <div v-show="isShowPrev" class="left-arrow" ref="left" @click="changeSlide(-1)">
      <v-icon size="36">mdi-chevron-left</v-icon>
    </div>
    <div v-show="isShowNext" class="right-arrow" ref="right" @click="changeSlide(1)">
      <v-icon size="36">mdi-chevron-right</v-icon>
    </div>
  </div>
</template>
<script>
export default {
  name: "TabCarousel",
  props: {
    width: String,
    height: String
  },
  data(){
    return {
      isShowPrev: false,
      isShowNext: true,

      tabContainer: null,
      carouselContainer: null,
      tabContainerWidth: 0,
      carouselContainerWidth: 0,

      tabItem: null,
      tabItemWith: 0,
      currentScrollLeft: 0
    }
  },
  mounted() {
    this.carouselContainer = this.$el.querySelector('.carousel')
    this.tabContainer = this.$el.querySelector('.tabs')
    this.tabItem = this.$el.querySelector('.active')
    this.updateTabWidth();
    window.addEventListener('resize', this.handleResize)
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      this.updateTabWidth();
    },
    updateTabWidth() {
      if (this.carouselContainer) {
        this.carouselContainerWidth = this.carouselContainer.offsetWidth
        this.tabContainerWidth = this.tabContainer.offsetWidth
        this.isShowNext = this.tabContainerWidth > this.carouselContainerWidth
      }
    },
    handleScroll(e) {
      this.isShowPrev = e.target.scrollLeft !== 0
      this.isShowNext = e.target.scrollLeft !== e.target.scrollWidth - e.target.offsetWidth
    },
    changeSlide(delta) {
      const carousel = this.$el.querySelector('.carousel')
      const width = carousel.offsetWidth
      const scroll = carousel.scrollLeft
      const scWidth = carousel.scrollWidth
      const fullWidth = scWidth - width
      let scrollActive = scroll + 120
      if (scrollActive >= fullWidth) {
        scrollActive = fullWidth
      }
      carousel.scrollTo(scrollActive * delta, 0)
    }
  }
}
</script>



<style scoped lang="scss">
.carousel-wrapper {
  position: relative;
  margin: 0 17px;
}
.carousel {
  display: flex;
  align-items: center;
  overflow: scroll;
  white-space: nowrap;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.carousel::-webkit-scrollbar {
  display: none;
}
.carousel > * {
  scroll-snap-align: start;
}

.left-arrow,
.right-arrow {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
  user-select: none;
  font-size: 40px;
  width: 58px;
}
.left-arrow {
  top: 20px;
  left: -30px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: start;
  justify-content: flex-start;
  color: rgb(61, 70, 83);
}

.right-arrow {
  top: 20px;
  right: -30px;
  height: 100%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: end;
  justify-content: flex-end;
  color: rgb(61, 70, 83);
}
.hide {
  display: none;
}

</style>