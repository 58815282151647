import request from "@/utils/request";

// super admin
export function getRevenue(data) {
  return request({
    url: "/mgr/dashboard/getAdminOverView",
    method: "get",
    params: data,
  });
}

export function getDetailReport(data) {
  return request({
    url: "/mgr/dashboard/getAdminDetailReport",
    method: "get",
    params: data,
  });
}

// sale person
export function getSalePersonRevenue(data) {
  return request({
    url: "/mgr/dashboard/getSalePersonOverView",
    method: "get",
    params: data,
  });
}

export function getSalePersonDetailReport(data) {
  return request({
    url: "/mgr/dashboard/getSalePersonDetailReport",
    method: "get",
    params: data,
  });
}

//buying department
export function getBuyingDepartmentRevenue(data) {
  return request({
    url: "/mgr/dashboard/getBuyingDepartmentOverView",
    method: "get",
    params: data,
  });
}

export function getBuyingDepartmentDetailReport(data) {
  return request({
    url: "/mgr/dashboard/getBuyingDepartmentDetail",
    method: "get",
    params: data,
  });
}
