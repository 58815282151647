import request from '@/utils/request'

export function connectAuthenticationXero() {
    return request({
        url: '/xero/authentication',
        method: 'get',
    })
}
export function getTokenXero(params) {
    return request({
        url: '/xero/token',
        method: 'get',
        params: params
    })
}

