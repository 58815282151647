import stompClient from "../../services/stomp/stompClient";

const state = () => {
  return {
    connected: false,
  };
};

const mutations = {
  SET_CONNECTED: (state, status) => {
    state.connected = status;
  },
};

const actions = {
  async connectWS() {
    await stompClient.connect(() => {
      console.log("Connect socket successfully!");
    });
  },

  disconnectWS() {
    stompClient.disconnect();
  },
};

const getters = {
  getStompConnect: (state) => state.connected,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
