// format price $1, -$1
export function formatPrice(value) {
  // convert into number if value is string
  const numberValue =
    typeof value === "string" ? parseFloat(value) : Number(value);

  // check if numberValue is NaN
  if (isNaN(numberValue)) {
    return "Invalid value";
  }

  if (numberValue >= 0) {
    return `$${numberValue.toFixed(2)}`;
  } else {
    return `-$${Math.abs(numberValue).toFixed(2)}`;
  }
}

//handle error api
export function handleApiError(error, showNotification) {
  console.error(error.message);
  if (showNotification) {
    const errorMessage =
      error.response?.data?.errorMessage || "An error occurred";
    showNotification("error", errorMessage);
  }
}

// save last page
export function saveLastPageToLocalStorage(pageName, id, idNo) {
  const latestPages = JSON.parse(localStorage.getItem("latestPages")) || {};
  latestPages[pageName] = { id: id, name: idNo };
  localStorage.setItem("latestPages", JSON.stringify(latestPages));
}

// convert title
export function convertToTitleCase(key) {
  return key
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/^\w/, (c) => c.toUpperCase());
}
