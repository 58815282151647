const state = () => {
  return {
    filterList: {
      makeId: "",
      modelId: "",
      seriesId: "",
      search: "",
    },
  };
};

const mutations = {
  SET_SELECTED_FILTER_LIST: (state, filterList) => {
    state.filterList = filterList;
  },
  SET_CLEAR_FILTER_LIST: (state, payload) => {
    state.filterList = payload;
  },
};

const actions = {
  setSelectedFilterList({ commit }, filterList) {
    commit("SET_SELECTED_FILTER_LIST", filterList);
  },
  setClearFilter({ commit }) {
    const payload = {
      makeId: "",
      modelId: "",
      seriesId: "",
      search: "",
    };
    commit("SET_CLEAR_FILTER_LIST", payload);
  },
};

const getters = {
  getSelectedFilterList(state) {
    return state.filterList;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
