import request from "@/utils/request";
export function getMakeList(data, signal) {
  return request({
    url: "/mgr/make/queryMakePagination",
    method: "get",
    params: data,
    signal,
  });
}
export function deleteMakeById(makeId) {
  return request({
    url: `/mgr/make/removeMake/${makeId}`,
    method: "delete",
  });
}
export function saveMake(data) {
  return request({
    url: "/mgr/make/saveMake",
    method: "post",
    data,
  });
}

export function getModelList(data, signal) {
  return request({
    url: "/mgr/model/queryModelPagination",
    method: "get",
    params: data,
    signal,
  });
}
export function deleteModelById(modelId) {
  return request({
    url: `/mgr/model/removeModel/${modelId}`,
    method: "delete",
  });
}
export function saveModel(data) {
  return request({
    url: "/mgr/model/saveModel",
    method: "post",
    data,
  });
}

export function getSeriesList(data, signal) {
  return request({
    url: "/mgr/series/querySeriesPagination",
    method: "get",
    params: data,
    signal,
  });
}
export function deleteSeriesById(seriesId) {
  return request({
    url: `/mgr/series/removeSeries/${seriesId}`,
    method: "delete",
  });
}
export function saveSeries(data) {
  return request({
    url: "/mgr/series/saveSeries",
    method: "post",
    data,
  });
}
