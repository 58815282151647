// src/services/stomp/stompClient.js
import { Client } from "@stomp/stompjs";
import SockJS from "sockjs-client";
import store from "../../store/store";

class StompService {
  constructor() {
    if (!StompService.instance) {
      this.client = null;
      StompService.instance = this;
    }
    return StompService.instance;
  }

  connect(onConnected) {
    return new Promise((resolve, reject) => {
      if (!this.client || !this.client.connected) {
        const socket = new SockJS(`${process.env.VUE_APP_BASE_API}/ws`);
        this.client = new Client({
          webSocketFactory: () => socket,
          reconnectDelay: 5000,
          onConnect: () => {
            store.commit("stompClient/SET_CONNECTED", true);
            onConnected();
            resolve();
          },
          onDisconnect: () => {
            store.commit("stompClient/SET_CONNECTED", false);
            console.log("Disconnected from STOMP server");
          },
          onStompError: (error) => {
            console.error("STOMP error: ", error);
            reject(error);
          },
        });
        this.client.activate();
      } else {
        console.log("STOMP client is already connected.");
        resolve();
      }
    });
  }

  subscribe(topic, callback) {
    if (this.client && this.client.connected) {
      return this.client.subscribe(topic, (message) => {
        const data = message.body;
        callback(data);
      });
    }
  }

  send(destination, body) {
    return new Promise((resolve, reject) => {
      if (this.client && this.client.connected) {
        this.client.publish({
          destination: destination,
          body: JSON.stringify(body),
        });
        resolve();
      } else {
        reject(new Error("STOMP client is not connected"));
      }
    });
  }

  disconnect() {
    if (this.client) {
      this.client.deactivate();
    }
  }
}

const instance = new StompService();

export default instance;
