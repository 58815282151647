/**
 * @typedef {import('axios').AxiosInstance} AxiosInstance
 */

export class AuthService {
  /**
   * @param {AxiosInstance} axios
   */
  constructor(axios) {
    this.axios = axios;
  }

  async login(userInfo) {
    const res = await this.axios.post("login", userInfo);
    return res.data;
  }
}
