import { apiService } from "../../services/api-service";

const state = () => {
  return {
    form: {},
    colorOptions: [],
    adjustTypeOptions: [],
    location: {
      isLocationloading: false,
      inputSearch: null,
      locationOptions: [],
    },
    stockAjustmentForm: {
      statusId: null,
      qty: 0,
      notes: "",
    },
    itemPictures: [],
    vehicleImage: File,
    isLoading: false,
    filterData: {},
  };
};

const mutations = {
  SET_CACHE_FILTER: (state, filterData) => {
    state.filterData = filterData;
  },
};

const actions = {
  async fetchData() {
    try {
      const { data } = await this.$api.inventory.getTagNoDetail({
        tagNo: this.tagId,
      });
      this.form = {
        acqId: data.acqId,
        color: data.color,
        colorId: data.colorId,
        condition: data.condition,
        configId: data.configId,
        cost: data.cost,
        engine: data.engine,
        initId: data.initId,
        invoiceId: data.invoiceId,
        invoiceNo: data.invoiceNo,
        location: data.location,
        locationId: data.locationId,
        make: data.make,
        matchType: data.matchType,
        model: data.model,
        notes: data.notes,
        odometer: data.odometer,
        partDesc: data.partDesc,
        partNo: data.partNo,
        partId: data.partId,
        publicNotes: data.publicNotes,
        purchaseOrderId: data.purchaseOrderId,
        purchaseOrderItemId: data.purchaseOrderItemId,
        purchased: data.purchased,
        retail: data.retail,
        series: data.series,
        stockId: data.stockId,
        stockNo: data.stockNo,
        tagNo: data.tagNo,
        trade: data.trade,
        transmission: data.transmission,
        type: data.type,
        vehicleId: data.vehicleId,
        year: data.year,
      };
      await this.getLocationOptions(data.location);
      await this.getItemPictures({
        configID: data.configId,
        initID: data.initId,
        partID: data.partId,
      });
    } catch (err) {
      console.error("fetchData", err.message);
    }
  },
  async getPicture() {
    const result = await apiService.inventory.getStockItemPicture({
      vehicleID: this.$route.params.id,
    });
    const { code, data } = result;
    if (code === 200) {
      this.stockPictures = data.map((res) => {
        return {
          imgUrl: res.fileUrl,
          imgName: res.fileName,
        };
      });
    }
  },
  setCacheFilter({ commit }, filterData) {
    commit("SET_CACHE_FILTER", filterData);
  },
};

const getters = {
  getCacheFiltered(state) {
    return state.filterData;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
