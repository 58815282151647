/**
 * @typedef {import('axios').AxiosInstance} AxiosInstance
 */

export class SaleReportService {
    /**
     * @param {AxiosInstance} axios
     */
    constructor(axios) {
        this.axios = axios;
    }

    async quoteReport(data) {
        const result = await this.axios.post(
            "/pdf/quoteReport",
            data
        );
        return result.data;
    }
    async creditReport(data) {
        const result = await this.axios.post(
            "/pdf/creditReport",
            data
        );
        return result.data;
    }
    async itemSalesReport(data) {
        const result = await this.axios.post(
            "/pdf/itemSalesReport",
            data
        );
        return result.data;
    }
    async transactionReport(data) {
        const result = await this.axios.post(
            "/pdf/transactionReport",
            data
        );
        return result.data;
    }
    async transactionReportByPerson(data) {
        const result = await this.axios.post(
            "/pdf/transactionReportByPerson",
            data
        );
        return result.data;
    }
}
