/**
 * @typedef {import('axios').AxiosInstance} AxiosInstance
 */

export class VehicleAcquisitionService {
    /**
     * @param {AxiosInstance} axios
     */
    constructor(axios) {
      this.axios = axios;
    }  
}
  