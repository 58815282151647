import request from "@/utils/request";
export function getListSaleReportSummary(data) {
    return request({
        url: "/mgr/saleReport/getSummary",
        method: "post",
        data,
    });
}
export function getInvoiceList(data) {
    return request({
        url: "/mgr/saleReport/queryInvoicesList",
        method: "post",
        data,
    });
}
export function quoteReport(data) {
    return request({
        url: "/pdf/quoteReport",
        method: "post",
        data,
    }, {responseType: 'text'});
}

