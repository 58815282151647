import request from '@/utils/request'

export function getRoleList(data) {
  return request({
    url: '/manageUser/getRole',
    method: 'get',
  })
}

export function getPermissionByRole(roleId) {
    return request({
        url: `/manageUser/getPermissionByRole/${roleId}`,
        method: 'get',
    })
}

export function getRoleDetail(roleId) {
    return request({
        url: `/manageUser/getRoleDetail/${roleId}`,
        method: 'get',
    })
}

export function deleteUser(userId) {
    return request({
        url: `/manageUser/deleteUser/${userId}`,
        method: 'delete',
    })
}


export function updateRole(data) {
    return request({
      url: '/manageUser/updateRole',
      method: 'post',
      data
    })
}
