import request from "@/utils/request";

export function getListPrice(payload, signal) {
  return request({
    url: "/mgr/searchPriceList/querySearchPrice",
    method: "get",
    params: payload,
    signal,
  });
}
