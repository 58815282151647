import request from "@/utils/request";

export const getPaymentTypeList = () => {
  return request({
    url: "/mgr/common/queryPaymentTypeList",
    method: "post",
  });
};

export function getUserList() {
  return request({
    url: "/mgr/common/queryUserList",
    method: "post",
  });
}

export function getContactList(data) {
  return request({
    url: "/mgr/common/queryContactList",
    method: "post",
    data: {
      contactName: data,
      pageNum: 1,
      pageSize: 5,
    },
  });
}
export function printTag(tagNo) {
  return request({
    url: `/mgr/common/pdf/tag/${tagNo}`,
    method: "get",
  });
}

export function getLocationList(data, signal) {
  return request({
    url: "/mgr/location/queryLocationList",
    method: "post",
    data: data,
    signal,
  });
}
export function removeLocation(locationId) {
  return request({
    url: `/mgr/location/removeLocation/${locationId}`,
    method: "delete",
  });
}
export function saveLocation(data) {
  return request({
    url: `/mgr/location/saveLocation`,
    method: "post",
    data: data,
  });
}
/**
 * 获取 part type
 * @returns
 */
export function getPartTypeList() {
  return request({
    url: "/mgr/common/queryPartType",
    method: "post",
  });
}

/**
 * 获取汽车品牌
 * @returns
 */
export function getMakeList() {
  return request({
    url: "/mgr/common/queryMakeList",
    method: "post",
  });
}

/**
 * 根据汽车品牌获取对应的汽车模型
 * @returns
 */
export function getModelList(data) {
  // console.log(data,"?")
  return request({
    url: "/mgr/common/queryModelList",
    method: "post",
    params: data,
  });
}

/**
 * 根据汽车品牌和汽车模型获取汽车系列号码
 * @param {FormData} data
 * @returns
 */
export function getSeriesList(data) {
  // console.log(data,"?")
  return request({
    url: "/mgr/common/querySeriesList",
    method: "post",
    params: data,
  });
}

/**
 * 获取汽车模型类型
 * @returns
 */
export function getModelTypeList() {
  return request({
    url: "/mgr/common/queryModelTypeList",
    method: "post",
  });
}

/**
 * 获取汽车年份list
 * @returns
 */
export function getYearList() {
  return request({
    url: "/mgr/common/queryYear",
    method: "post",
  });
}

/**
 * 获取引擎的list
 * @returns
 */
export function getEngineList(data) {
  return request({
    url: "/mgr/common/queryEngineCodeList",
    method: "post",
    params: data,
  });
}

/**
 * 获取变速箱的list
 * @param {*} data
 * @returns
 */
export function getTransimisssionList(data) {
  return request({
    url: "/mgr/common/queryTransCodeList",
    method: "post",
    params: data,
  });
}

/**
 * 获取汽车颜色的list
 * @param {*} data
 * @returns
 */
export function getColorList(data) {
  return request({
    url: "/mgr/common/queryColourList",
    method: "post",
    params: data,
  });
}

/**
 * 获取服务费用类型
 * @returns
 */
export function getExpenseTypeList() {
  return request({
    url: "/mgr/common/queryExpenseTypeList",
    method: "post",
  });
}

export function getReferredList() {
  return request({
    url: "/mgr/common/queryReferredList",
    method: "post",
  });
}

export function createRefGroup(data) {
  return request({
    url: "/mgr/common/createRefGroup",
    method: "post",
    data: data,
  });
}

export function deleteRefGroup(id) {
  return request({
    url: `/mgr/common/deleteRefGroup/${id}`,
    method: "delete",
  });
}

export function getDriverList() {
  return request({
    url: "/mgr/common/queryDriverList",
    method: "post",
  });
}

export function getStockTypeList() {
  return request({
    url: "/mgr/common/getStockType",
    method: "post",
  });
}

export function getStockPurchaseTypeList() {
  return request({
    url: "/mgr/common/getStockPurchaseType",
    method: "post",
  });
}

export function getWriteOffList() {
  return request({
    url: "/mgr/common/getWriteOff",
    method: "post",
  });
}

export function getPlateList() {
  return request({
    url: "/mgr/common/getPlates",
    method: "post",
  });
}

export function getAdjustTypeList() {
  return request({
    url: "/mgr/common/adjustType",
    method: "post",
  });
}

export function getCourierList(data) {
  return request({
    url: "/mgr/common/queryCourierList",
    method: "post",
    params: data,
  });
}

export function getGroup1List() {
  return request({
    url: "/mgr/common/queryGroup1List",
    method: "post",
  });
}

export function getGroup2List() {
  return request({
    url: "/mgr/common/queryGroup2List",
    method: "post",
  });
}
