import request from "@/utils/request";

/**
 * @typedef {import('axios').AxiosInstance} AxiosInstance
 */
// import store from '../store/store';

export class InventoryService {
  /**
   * @param {AxiosInstance} axios
   */
  constructor(axios) {
    this.axios = axios;
    // this.axios.defaults.headers.common['Authorization'] = store.getters.userInfo.token;
  }

  async updatePartInStock(data) {
    const res = await this.axios.post(
      "/mgr/inventory/browseVehicles/submitSinglePart",
      data
    );
    return res.data;
  }

  async getInventoryPartsList(data) {
    const res = await this.axios.post(
      "/mgr/inventory/queryInventoryPartsList",
      data
    );
    return res.data;
  }

  async getStockItemPicture({ params }) {
    const res = await this.axios.get(
      "/mgr/inventory/browseVehicles/pictures",
      params
    );
    return res.data;
  }

  async getInventoryList(data) {
    const res = await this.axios.post(
      "/mgr/inventory/queryInventoryList",
      data
    );
    return res.data;
  }

  async getbrowseVehiclesList(data) {
    const res = await this.axios.post(
      "/mgr/inventory/browseVehicles/list",
      data
    );
    return res.data;
  }

  async getStockByVehicleId(vehicleId) {
    const res = await this.axios.get("/mgr/inventory/browseVehicles/detail", {
      vehicleId,
    });
    return res.data;
  }

  async deleteStockImages(isDeleteTagLocator, fileName) {
    const res = await this.axios.delete(
      `/mgr/inventory/browseVehicles/deleteImage/?isDeleteTagLocator=${isDeleteTagLocator}&fileName=${fileName}`
    );
    return res.data;
  }

  async getStockList(data) {
    const res = await this.axios.get(
      "/mgr/inventory/browseVehicles/getStockList",
      data
    );
    return res.data;
  }

  async addStockToInventory(data) {
    const res = await this.axios.post(
      "/mgr/inventory/browseVehicles/commit",
      data
    );
    return res.data;
  }

  async getTagNoDetail(data) {
    const res = await this.axios.get("/mgr/inventory/tagNoSearchDetail", data);
    return res.data;
  }

  async addStockAjustment(data) {
    const res = await this.axios.post("/mgr/inventory/adjust", data);
    return res.data;
  }

  async updateTagNoDetail(data) {
    const res = await this.axios.post("/mgr/inventory/tagNoSaveDetail", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return res.data;
  }

  async searchTagNo(data) {
    const res = await this.axios.get("/mgr/inventory/tagNoSearch", data);
    return res.data;
  }

  async globalSearch(data) {
    const res = await this.axios.get("/mgr/inventory/globalSeahayrch", data);
    return res.data;
  }

  async saveStock(data) {
    const res = await this.axios.post(
      "/mgr/inventory/browseVehicles/saveDetail",
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return res.data;
  }

  async getHistoryAndEvents(data) {
    const res = await this.axios.post(
      "/mgr/inventory/browseVehicles/queryHistoryAndEvents",
      data
    );
    return res.data;
  }

  async getStockItemPartPicture(data) {
    const res = await this.axios.get(
      "/mgr/inventory/browseStock/part/pictures",
      data
    );
    return res.data;
  }

  async getInventoryItemPicture(data) {
    const res = await this.axios.get("/mgr/inventory/pictures", {
      data,
    });
    return res.data;
  }

  async printTagLocation(locationID) {
    const res = await this.axios.get(`/pdf/printLocation/${locationID}`)
    return res.data;
  }
}
