import request from "@/utils/request";

// threshold
export function getThresholdList(data, signal) {
  return request({
    url: "/mgr/backOrderPart/getThresholdSettingList",
    method: "get",
    params: data,
    signal,
  });
}

export function getThresholdItem(data) {
  return request({
    url: "/mgr/backOrderPart/getBackOrderPartDetail",
    method: "post",
    data,
  });
}

export function updateThreshold(data) {
  return request({
    url: "/mgr/backOrderPart/editBackOrderPart",
    method: "post",
    data,
  });
}

//list
export function getBackOrderList(data, signal) {
  return request({
    url: "/mgr/backOrderPart/getBackOrderPartList",
    method: "get",
    params: data,
    signal,
  });
}

// fast sell history list
export function getFastSellHistoryList(data) {
  return request({
    url: "/mgr/backOrderPart/viewPriceHistory",
    method: "get",
    params: data,
  });
}
