/**
 * @typedef {import('axios').AxiosInstance} AxiosInstance
 */
// import store from '../store/store';

export class SettingService {
    /**
     * @param {AxiosInstance} axios
     */
    constructor(axios) {
      this.axios = axios;
      // this.axios.defaults.headers.common['Authorization'] = store.getters.userInfo.token;
    }
  
    async checkConnectedXeroAccount() {
        const result = await this.axios.get(
            `/xero/checkConnection`
        )
        return result.data;
    }
    
  }
  