const state = () => {
  return {
    filterData: {},
  };
};

const mutations = {
  SET_CACHE_FILTER: (state, filterData) => {
    state.filterData = filterData;
  },
};

const actions = {
  setCacheFilter({ commit }, filterData) {
    commit("SET_CACHE_FILTER", filterData);
  },
};
const getters = {
  getCacheFiltered(state) {
    return state.filterData;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
